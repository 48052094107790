<template>
  <div>
    <br />
    <span>{{ title }}</span>
    <template v-if="values">
      <bProgress
        :max="max"
        data-toggle="tooltip"
        v-b-tooltip.hover.html="tooltipText"
      >
        <bProgressBar
          v-for="section in values"
          :key="section.key"
          :value="section.value"
          :variant="section.variant"
          :striped="section.striped"
        />
      </bProgress>
    </template>
  </div>
</template>

<script>
export default {
  name: "OverviewProgressBar",
  props: {
    title: String,
    max: Number,
    values: Array,
  },
  computed: {
    tooltipText() {
      return this.values
        .map((item) => {
          return `${item.key}: ${item.value}`;
        })
        .join(" </br>");
    },
  },
};
</script>
